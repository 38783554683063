import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor dapp pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3"><span className="bluecolor">Benefits Of </span>Our <span className="transNone">dApp</span> Development Services</span>
            </h2>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Rapid Processing</h3>
                <p className="pharagraph">
                You can experience a rapid processing speed with our <span className="transNone">dApp</span> development, which ensures your transactions are completed in the blink of an eye. Our <span className="transNone">dApp</span> experts focus on delivering smooth interactions, prompt responses, and elevating user satisfaction through rapid processing.
                </p>
              </div>
              <div className="square" >
                <h3>Apt Design</h3>
                <p className="pharagraph">
                Our <span className="transNone">dApp</span> development company ensures a user-friendly design, enhancing engagement. Experience a streamlined navigation through our dApp's intuitive design which saves time for your users.  You can have an adaptable and responsive design with our dApp development service.
                </p>
              </div>
              <div className="square" >
                <h3>Immutable security</h3>
                <p className="pharagraph">
                Our <span className="transNone">dApp</span> development ensures unbreachable data integrity and secure data through immutable security protocols. We can assure you that our developed <span className="transNone">dApp</span> is safe from any malicious attacks and unauthorized access. We as a <span className="transNone">dApp</span> development company, adhere to demanding security standards.
                </p>
              </div>
              <div className="square" >
                <h3>Transparent and Trust</h3>
                <p className="pharagraph">
                Our <span className="transNone">dApp</span> development ensures transparency at every step, strengthening the trust among users. We guarantee you the authenticity of every transaction, building confidence in the integrity of the platform.
                </p>
              </div>
              <div className="square" >
                <h3>Data Trails and Audit</h3>
                <p className="pharagraph">
                We create unalterable data trails with our <span className="transNone">dApp</span> development that ensures complete transparency and accountability. Our <span className="transNone">dApp</span> makes the auditing process effortless and provides thorough insights into transactions and operations.
                </p>
              </div>
              <div className="square" >
                <h3>Self-Managing</h3>
                <p className="pharagraph">
                Our <span className="transNone">dApp</span> development empowers seamless self-management, reducing the need for constant manual intervention. Through our <span className="transNone">dApp</span> development services, anticipate and address the issues for smooth operation. Users can trust our dApp's self-managing capabilities to handle tasks independently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default Benificial
